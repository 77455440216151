
import ApiService from "@/core/services/ApiService"

import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { useRoute, useRouter } from "vue-router"
import ExpenseTypeComponent from "@/components/finance/definitions/expense-type.vue"
import { Tag, TagType } from "@/core/models/Tag"
import { CirclePlus } from "@element-plus/icons-vue"
import swalConfirm from "@/core/helpers/swal-confirm"

export default defineComponent({
  name: "expense-type-list",
  components: { ExpenseTypeComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()

    const newExpenseType = ref(false)

    const ExpenseTypeList = ref<Tag[]>([] as Tag[])

    const activeExpenseTypeId = ref<string | undefined>()

    const activeParentId = ref<string>()
    const activeParentName = ref<string>("")

    const treeProps = {
      id: "id",
      parentId: "parentId",
      children: "childs",
    }

    watch(
      () => props.add,
      newValue => {
        newExpenseType.value = true == newValue
      }
    )

    watch(
      () => route.params.expense_type_id,
      newValue => {
        activeExpenseTypeId.value = newValue as string
      }
    )

    const getExpenseTypeList = async () => {
      var config = {
        params: {
          tagType: TagType.ExpenseType,
        },
      }
      const { data } = await ApiService.query("tag/childs", config)
      ExpenseTypeList.value = data
    }

    async function removeExpenseType(expenseTypeId) {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("tag/remove/" + expenseTypeId)
        await getExpenseTypeList()
      }
    }

    function handleExpenseTypeDrawerClose() {
      activeParentId.value = undefined
      activeParentName.value = ""
      router.push({ name: "expense-type-list" })
    }

    const OnClickChild = (parentId, parentName) => {
      activeParentId.value = parentId as string
      activeParentName.value = parentName
      router.push({ name: "expense-type-add", params: { activeParentId: parentId } })
    }

    onMounted(async () => {
      activeExpenseTypeId.value = route.params.expense_type_id as string
      newExpenseType.value = props.add
      await getExpenseTypeList()
    })

    return {
      Edit,
      CirclePlus,
      removeExpenseType,
      getExpenseTypeList,
      activeExpenseTypeId,
      newExpenseType,
      handleExpenseTypeDrawerClose,
      ExpenseTypeList,
      activeParentId,
      OnClickChild,
      treeProps,
      activeParentName,
    }
  },
})
