
import { Tag, TagType } from "@/core/models/Tag"
import ApiService from "@/core/services/ApiService"

import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"

export default defineComponent({
  name: "expense-type-component",
  components: {},
  props: ["add", "id", "closed", "activeParentId", "activeParentName"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add } = toRefs(props)

    const expenseTypeData = ref<Tag>({} as Tag)

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const rules = ref({
      name: getRule(RuleTypes.TEXT50, "Ad"),
    })

    const getExpenseTypeTag = async id => {
      const { data } = await ApiService.get("tag/" + id)
      expenseTypeData.value = data
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getExpenseTypeTag(newValue)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
        }
      }
    )

    watch(
      () => props.activeParentId,
      newValue => {
        expenseTypeData.value.parentId = newValue ?? undefined
      }
    )

    const addExpenseType = async () => {
      expenseTypeData.value.tagType = TagType.ExpenseType
      const data = {
        ...expenseTypeData.value,
        parentId: props.activeParentId,
      }
      await ApiService.post("tag/add", data)
      emit("getlist")
      router.push({ name: "expense-type-list" })
    }
    const updateExpenseType = async () => {
      const config = { ...expenseTypeData.value }
      await ApiService.post("tag/edit", config)
      emit("getlist")
      router.push({ name: "expense-type-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addExpenseType()
          } else {
            updateExpenseType()
          }
        }
      })
    }

    onMounted(() => {
      expenseTypeData.value.parentId = props.activeParentId as string
    })

    return {
      showDrawer,
      newExpenseType: add,
      handleClose: props.closed,
      expenseTypeData,
      addOrUpdate,
      ruleFormRef,
      rules,
    }
  },
})
